import "@/styles/index.scss";
import MainLayout from "@/components/Templates/Main";
import { appWithTranslation } from "next-i18next";
import { motion, AnimatePresence } from "framer-motion";


function MyApp({ Component, pageProps, router }) {
  
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className="overflow-x-hidden w-screen"
        key={router.asPath}
        initial={{
          y: 0,
          opacity: 0,
          transition: { ease: "easeIn", duration: 0 },
        }}
        animate={{
          translateY: 0,
          opacity: 1,
          transition: { duration: .5, ease: "easeOut", staggerChildren: 1 },
        }}
        exit={{
          y: -300,
          opacity: 0,
          transition: { ease: "easeOut", duration: .5 },
        }}
      >
        <MainLayout>
          <Component {...pageProps} />
        </MainLayout>
      </motion.div>
    </AnimatePresence>
  );
}

export default appWithTranslation(MyApp);
