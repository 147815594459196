import Script from "next/script";

export default function CookieConfig({ id, currentLocale }) {
  if (id.length < 1) {
    return null;
  } else {
    const defaultLanguage = process.env.DEFAULT_SITE_LOCALE || "en";
    const regex1 = /^\/([a-z]{2})([_-][a-z]{2})?(\/.*)?/g
    const regex2 = /^([a-z]{2})\./
    const regex3 = /\.([a-z]{2,})$/

    const regex4 = /^\/([a-z]{2})([_-][a-z]{2})?(\/.*)?$/g
    const regex5 = /^([a-z]{2})\./
    const regex6 = /\.([a-z]{2,})$/
    return (
      <>
        {/* <Script
          strategy="beforeInteractive"
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={id}
          data-blockingmode="auto"
          type="text/javascript"
        ></Script> */}

        <Script
          id={`Cookiebot-${id}-${currentLocale}`}
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(C,oo,k,ie,b,o,t){
              if(k === "/") k = "/${defaultLanguage}"
              let a=C.scripts[0],c="en",d=C.createElement(ie),
              e=[${regex1}.exec(k),${regex2}.exec(oo),
              ${regex3}.exec(oo)],f={pt:"pt",en:"en",es:"es"},g=0;for(g of e){e=g?g[1]:t;if(g)break}e=e.length!=2?t:e;e=f[e]?f[e]:e;d.
              id=b;d.src="https://consent.cookiebot.com/uc.js";d.dataset.cbid=o;d.dataset.
              culture=e;d.async=!0;a.parentNode.insertBefore(d,a)}(document,location.hostname,
              location.pathname,"script","Cookiebot","${id}","${defaultLanguage}");
  `,
          }}
        />
        {/* <Script
          id={`CookiebotDecalration-${id}-${currentLocale}`}
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(de,cl,ar,at,i,o,n){
              let a=de.createElement(at),b=[${regex4}.exec(cl),
              ${regex5}.exec(ar),${regex6}.exec(ar)],c={pt:"pt",en:"en",es:"es"},d=de.scripts,e=d[d.length-1],f=0;
              for(f of b){b=f?f[1]:n;if(f)break}b=b.length!=2?o:b;b=c[b]?c[b]:b;a.id=i;a.dataset.
              culture=b;a.src='https://consent.cookiebot.com/'+o+'/cd.js';e.parentNode.
              insertBefore(a,e)}(document,location.pathname,location.hostname,"script",
              "CookieDeclaration","${id}","${defaultLanguage}");
  `,
          }}
        /> */}
      </>
    );
  }
}
