import Script from "next/script";

export default function AnalyticsConfig({ id }) {
  if (id.length < 1) {
    return null;
  } else {
    return (
      <>
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${id}');
  `,
          }}
        />
      </>
    );
  }
}

export function GTManager({ id }) {
  if (id.length < 1) {
    return null;
  } else {
    return (
      <>
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${id}');
  `,
          }}
        ></Script>
      </>
    );
  }
}

export function GTManagerAditional({ id }) {
  if (id.length < 1) {
    return null;
  } else {
    return (
      <>
        <noscript
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `,
          }}
        ></noscript>
      </>
    );
  }
}

export function MSClarity({ id }) {
  if (id.length < 1) {
    return null;
  } else {
    return (
      <Script
        id="msc"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
   (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", '${id}');
     `,
        }}
      ></Script>
    );
  }
}

export function MetaPixel({ id }) {
  if (id.length < 1) {
    return null;
  } else {
    return (
      <Script
        id="metaPixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${id}'); fbq('track', 'PageView')
         
     `,
        }}
      ></Script>
    );
  }
}
